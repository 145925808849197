import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";

class Header extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  render() {
    const { scrolled } = this.state;
    return (
      <>
        <header
          className={scrolled ? "main-header fixed-header" : "main-header"}
        >
          <div className="header-top">
            <div className="auto-container">
              <div className="inner">
                <div className="top-left">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to={"/#"}>
                        <img
                          src={require("../../assets/images/sticky-logo.png")}
                          alt=""
                          width={"100"}
                          height={"50"}
                        />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="top-middile">
                  <div className="contact-info">
                    <div className="single-info">
                      <div className="icon-box">
                        <i className="flaticon-call-1"></i>
                      </div>
                      <div className="text">Phone Number</div>

                      <h4>
                        <a href={"tel:+917204320381"}>+91 7204320381</a>
                      </h4>
                    </div>
                    <div className="single-info ">
                      <div className="icon-box">
                        <i className="flaticon-email-4"></i>
                      </div>
                      <div className="text">Email Address</div>
                      <h4>
                        <a href={"mailto:freeman@bridgemark.in"}>
                          freeman@bridgemark.in
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                <div className="nav-outer clearfix">
                  <div className="mobile-nav-toggler">
                    <span className="icon fal fa-bars"></span>
                  </div>

                  <nav className="main-menu navbar-expand-md navbar-light">
                    <Menu />
                  </nav>

                  <ul className="social-links clearfix">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/bridgemarktaxconsultant"
                      >
                        <span className="fab fa-facebook-f"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/bridgemark.in/"
                      >
                        <span className="fab fa-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }
          >
            <div className="auto-container clearfix">
              <div className="logo pull-left">
                <Link to={"/#"} title="">
                  <img
                    src={require("../../assets/images/sticky-logo.png")}
                    alt=""
                    title=""
                    width={"100"}
                    height={"50"}
                  />
                </Link>
              </div>

              <div className="pull-right">
                <nav className="main-menu clearfix">
                  <Menu />
                </nav>
              </div>
            </div>
          </div>

          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-cancel"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo">
                <Link to={"/#"}>
                  <img src="assets/images/logo.png" alt="" title="" />
                </Link>
              </div>
              <div className="menu-outer">
                <Menu />
              </div>
              <div className="social-links">
                <ul className="clearfix">
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-facebook-square"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-instagram"></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
